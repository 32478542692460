@import '../colors.scss';

.mat-mdc-form-field-icon-suffix > .mat-icon {
  position: relative;
  top: 4px;
  font-size: 20px;
}
.mat-mdc-form-field.mat-focused .mat-form-field-suffix > .mat-icon,
.mat-form-field.mat-focused .mat-datepicker-toggle-default-icon {
  color: $color-primary;
}
.mat-mdc-form-field.mat-form-field-disabled .mat-form-field-suffix > .mat-icon {
  color: rgba(0, 0, 0, 0.4);
}
