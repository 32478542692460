@import '../colors.scss';

.mat-mdc-table {
  display: inline-block;
  width: 100%;
}
/*  Fix the font in the table header  */
.mat-sort-header-container {
  color: $color-primary;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
/*  Fix the arrow in the table header  */
.mat-sort-header-arrow.ng-trigger {
  margin-top: 6px;
}
/*  Fix rows  */
.mat-mdc-header-row,
.mat-mdc-row {
  flex: none!important;
  display: block!important;
  min-height: 0!important;
  padding: 0!important;
  border: none!important;
  float: left;
  width: 100%;
}
.mat-mdc-row {
  line-height: 0;
  position: relative;
}
.mat-mdc-row.not-shown::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 7px;
  border-right-color: #ff0000;
  border-top-color: #ff0000;
}
.mat-mdc-table:not(.mat-mdc-table-no-hover) .mat-mdc-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.mat-mdc-header-row {
  line-height: 25px;
  padding-bottom: 12px!important;
}
.mat-mdc-cell,
.mat-mdc-header-cell {
  flex: none!important;
  display: inline-block!important;
  font-family: "Open Sans", sans-serif!important;
  float: left;
  min-width: 5px;
  min-height: 5px;
}
.mat-mdc-cell {
  font-size: 16px!important;
  line-height: 24px;
}
/*  Pagination fixes  */
.mat-mdc-paginator-page-size,
.mat-mdc-paginator-range-actions {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px;
}
.mat-select-value {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px;
}
.mat-paginator-page-size-select .mat-select-value {
  text-align: center;
}
/*  Fix bootstrap form-row  */
.mat-mdc-row.form-row {
  margin: 0;
}
