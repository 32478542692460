.app-bootstrap .icon.icon-logo {
  font-size: 220px;
  color: #32699C;
}
.app-bootstrap .progress {
  position:relative;
  height:4px;
  display:block;
  width:100%;
  background-color:#c2d2e1;
  background-clip:padding-box;
  margin:-60px 0 1rem;
  overflow:hidden
}
.app-bootstrap .progress div {
  background-color:#32699c
}.app-bootstrap .progress div:after,
.progress div:before {
  content:'';
  position:absolute;
  background-color:inherit;
  top:0;
  left:0;
  bottom:0;
  will-change:left,right
}
.app-bootstrap .progress div:before {
  -webkit-animation:indeterminate 2.1s cubic-bezier(.65,.815,.735,.395) infinite;animation:indeterminate 2.1s cubic-bezier(.65,.815,.735,.395) infinite
}
.app-bootstrap .progress div:after{
  -webkit-animation:indeterminate-short 2.1s cubic-bezier(.165,.84,.44,1) infinite;animation:indeterminate-short 2.1s cubic-bezier(.165,.84,.44,1) infinite;
  -webkit-animation-delay:1.15s;animation-delay:1.15s
}
@-webkit-keyframes indeterminate{
  0%{
    left:-35%;right:100%
  }
  100%,60%{
    left:100%;right:-90%
  }
}
@keyframes indeterminate{
  0%{
    left:-35%;right:100%
  }100%,60%{
    left:100%;right:-90%
  }
}
@-webkit-keyframes indeterminate-short{
  0%{
    left:-200%;right:100%
  }
  100%,60%{
    left:107%;right:-8%
  }
}@keyframes indeterminate-short{
  0%{
    left:-200%;right:100%
  }
  100%,60%{
    left:107%;right:-8%
  }
}
.app-bootstrap{
  position:fixed;
  top:calc(50vh - 250px);
  width:300px;
  left:calc(50% - 150px);
  text-align:center
}
.app-bootstrap img{
  width:100%;
  height:auto;
  max-width:225px
}
/*  Custom Icons  */
/* Generated by Glyphter (http://www.glyphter.com) on  Tue Mar 07 2023*/
@font-face {
  font-family: 'nico icon font';
  src: url('../fonts/nico-icon-font.eot');
  src: url('../fonts/nico-icon-font.eot?#iefix') format('embedded-opentype'),
       url('../fonts/nico-icon-font.woff') format('woff'),
       url('../fonts/nico-icon-font.ttf') format('truetype'),
       url('../fonts/nico-icon-font.svg#nico-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before{
display: inline-block;
 font-family: 'nico icon font';
 font-style: normal;
 font-weight: normal;
 line-height: 1;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale
}
.icon-applications:before{content:'\0042';}
.icon-arrow-left:before{content:'\0043';}
.icon-arrow-right:before{content:'\0044';}
.icon-calendar:before{content:'\0045';}
.icon-claims:before{content:'\0046';}
.icon-client-services:before{content:'\0047';}
.icon-csv:before{content:'\0048';}
.icon-dashboard:before{content:'\0049';}
.icon-directbill:before{content:'\004a';}
.icon-documents:before{content:'\004b';}
.icon-download:before{content:'\004c';}
.icon-email:before{content:'\004d';}
.icon-fax:before{content:'\004e';}
.icon-garage:before{content:'\004f';}
.icon-general-liability:before{content:'\0050';}
.icon-guides-manuals:before{content:'\0051';}
.icon-handshake:before{content:'\0052';}
.icon-home:before{content:'\0053';}
.icon-information:before{content:'\0054';}
.icon-initial-claim-reporting:before{content:'\0055';}
.icon-instant-online-indication:before{content:'\0056';}
.icon-interactive:before{content:'\0057';}
.icon-library:before{content:'\0058';}
.icon-lock:before{content:'\0059';}
.icon-logo:before{content:'\005a';}
.icon-magnify:before{content:'\0061';}
.icon-mailbox:before{content:'\0062';}
.icon-marketing-materials:before{content:'\0063';}
.icon-menu:before{content:'\0064';}
.icon-multi-vehicle:before{content:'\0065';}
.icon-news:before{content:'\0066';}
.icon-nico-rate:before{content:'\0067';}
.icon-online-access:before{content:'\0068';}
.icon-options:before{content:'\0069';}
.icon-paper-airplane:before{content:'\006a';}
.icon-parameters:before{content:'\006b';}
.icon-pdf:before{content:'\006c';}
.icon-pencil:before{content:'\006d';}
.icon-phone:before{content:'\006e';}
.icon-policy-dashboard:before{content:'\006f';}
.icon-print-documents:before{content:'\0070';}
.icon-printer:before{content:'\0071';}
.icon-public:before{content:'\0072';}
.icon-recurring-payment:before{content:'\0073';}
.icon-reports:before{content:'\0074';}
.icon-special-types:before{content:'\0075';}
.icon-submission-dashboard:before{content:'\0076';}
.icon-tech-support:before{content:'\0077';}
.icon-training:before{content:'\0078';}
.icon-trophy:before{content:'\0079';}
.icon-truck:before{content:'\007a';}
.icon-xml:before{content:'\0030';}
.icon-wheel-topics:before{content:'\0031';}
.icon-playbook:before{content:'\0032';}
.icon-quick-reference:before{content:'\0033';}