.osano-cm-content {
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

.osano-cm-link {
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
}

.osano-cm-button {
	padding: 16px 40px;
	background-color: #32699C;
	color: #fff;
	border: none;
}