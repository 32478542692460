@import '../colors.scss';

.mat-mdc-fab.mat-accent {
  background-color: transparent;
  font-size: 30px;
  color: $color-primary;
}
.mat-mdc-fab.mat-accent:hover,
.mat-fab.mat-accent:active {
  background-color: $color-hover-grey;
}

/*  Since we have our own icons, that breaks positioning..  */
.mat-mdc-fab.mat-accent i.fa.fa-print {
  margin-left: 4px;
  margin-top: -4px;
}