@import '../colors.scss';

/*  color  */
.text-primary,
a.text-primary,
a.text-primary:hover,
a.text-primary:focus {
  color: $color-primary!important;
}
/*  border  */
.border-primary {
  border-color: $color-primary !important;
}
/*  background  */
.modal.fade.in {
  background: $color-primary-transparent;
}
/*  background-color  */
.primary-color,
.bg-primary {
  background-color: $color-primary!important;
}