@import '../colors.scss';

.text-primary-dark {
  color: $color-primary-dark;
}
a.text-primary-dark:hover,
a.text-primary-dark:active {
  color: $color-primary-dark;
}
.bg-primary-dark {
  background-color: $color-primary-dark;
}