
body,
.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-button-toggle,
.mat-select,
.mat-option,
.mat-form-field,
.mat-checkbox,
.mat-dialog-title,
.mat-menu-item,
.mat-slide-toggle-content {
  font-family: "Open Sans", sans-serif;
}
/*  Since we had to change the font.... Now spacing is off  */
.mat-mdc-form-field {
  line-height: 1.2;
}
