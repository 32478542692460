body {
  color: #58585A;
}
// Material theme with overrides
//@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'styles/material-theme.scss';
@import 'styles/material-overrides/cursors.scss';
@import 'styles/material-overrides/font.scss';
@import 'styles/material-overrides/no-outlines.scss';
@import 'styles/material-overrides/remove-shadows.scss';
@import 'styles/material-overrides/mat-button-toggle.scss';
@import 'styles/material-overrides/mat-fab-accent.scss';
@import 'styles/material-overrides/input-display-block.scss';
@import 'styles/material-overrides/backdrop.scss';
@import 'styles/material-overrides/z-index-fixes.scss';
@import 'styles/material-overrides/modal-allow-scrolling.scss';
@import 'styles/material-overrides/snackbar.scss';
@import 'styles/material-overrides/form-field-suffix-icon.scss';
@import 'styles/material-overrides/table.scss';
@import 'styles/material-overrides/checkbox.scss';
@import 'styles/material-overrides/dialog.scss';
// Bootstrap overrides. Faster to load from CDN and then override
@import 'styles/bootstrap-overrides/label.scss';
@import 'styles/bootstrap-overrides/primary-color.scss';
@import 'styles/bootstrap-overrides/primary-color-dark.scss';
@import 'styles/bootstrap-overrides/secondary-color.scss';
// Swiper overrides. Bypass font-face import
@import 'styles/swiper-overrides/font-face.scss';
// Own Styles
@import 'styles/clear-button.scss';
@import 'styles/download-button.scss';
@import 'styles/tables.scss';
@import 'styles/semi-bold-font.scss';
@import 'styles/col-table-loading.scss';
@import 'styles/phone-links.scss';
@import 'styles/errors.scss';
@import 'styles/osano.scss';
// Speed up animations
.animated {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
}

.adminSaveError {
  background: red;
}