/*  Add this to a column to center a spinner inside of it.  */
.col-table-loading {
	position: relative;
}
.col-table-loading .mat-mdc-table {
	min-height: 275px;
}
.col-table-loading .spinner-container {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: .5;
}
.col-table-loading .spinner-container .mat-mdc-progress-spinner {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: calc(50% - 50px);
}
.col-table-loading .no-results-container {
	position: absolute;
	width: 100%;
	height: 100%;
}
.col-table-loading .no-results-container > div {
	text-align: center;
	position: relative;
	top: calc(50% - 15px);
}