/*  Needed for material theming  */
@use '@angular/material' as mat;

@include mat.core();
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
/*  Custom material color palette  */
$md-mat-blue: (
  50 : #e6edf3,
  100 : #c2d2e1,
  200 : #99b4ce,
  300 : #7096ba,
  400 : #5180ab,
  500 : #32699c,
  600 : #2d6194,
  700 : #26568a,
  800 : #1f4c80,
  900 : #133b6e,
  A100 : #a5c9ff,
  A200 : #72aaff,
  A400 : #3f8bff,
  A700 : #257bff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);
$md-mat-green: (
  50 : #f1f8e8,
  100 : #ddeec5,
  200 : #c6e39f,
  300 : #afd779,
  400 : #9ecf5c,
  500 : #8dc63f,
  600 : #85c039,
  700 : #7ab931,
  800 : #70b129,
  900 : #5da41b,
  A100 : #ebffdb,
  A200 : #cfffa8,
  A400 : #b3ff75,
  A700 : #a5ff5c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$custom-theme-primary: mat.m2-define-palette($md-mat-blue);
$custom-theme-accent: mat.m2-define-palette($md-mat-green);
$custom-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);
$custom-theme: mat.m2-define-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);
@include mat.all-component-themes($custom-theme);
