/*  I guess we need a specialized clear button to clear 2-3 fields...  */
.mat-mdc-raised-button.mat-primary.clear-button {
  border-radius: 6px;
  height: 32px;
  line-height: 32px;
  padding-left: 8px;
  padding-right: 35px;
}
.mat-mdc-raised-button.mat-primary.clear-button i {
  position: absolute;
  top: 0;
  right: 0;
  width: 27px;
  font-style: inherit;
  border-left: 1px solid white;
  font-weight: bold;
}