@import 'colors.scss';

/*  Styling for fake table of divs  */
.table-row {
  width:100%;
  display: inline-block;
}
.table-row.table-row-clickable {
  cursor: pointer;
  border-radius: 2px;
}
.table-row.table-row-clickable:hover,
.table-row.table-row-clickable:active {
  background-color: $color-hover-grey;
}
.table-row > div {
  float: left;
  min-height: 1px;
}
.table-row > div.font-weight-bold {
  font-size: 18px;
  padding-bottom: 20px;
}