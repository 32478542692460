@import '../colors.scss';

.mat-button-toggle-group-appearance-standard {
  border: none !important;
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  color: $color-primary !important;
  background: transparent;
  background-color: transparent !important;
  border-color: $color-primary !important;
  border-left-color: $color-primary !important;
}
.mat-button-toggle {
  border: 2px solid $color-light-grey;
  border-radius: 10em;
  margin-right: 10px;
  font-size: 15px !important;
  line-height: 26px !important;
  margin: 0 4px;
  font-weight: bold !important;
  color: #717171 !important;
}
.mat-button-toggle:hover,
.mat-button-toggle:active,
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle:hover,
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle:active {
  border-color: $color-primary !important;
  background: transparent;
  color: $color-primary !important;
  border-left-color: $color-primary !important;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
.mat-button-toggle .mat-button-toggle-ripple {
  border-radius: 10em;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px!important;
}
.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  opacity: 0!important;
}
.mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard + .mat-button-toggle-appearance-standard {
  border-left-color: $color-light-grey !important;
}
