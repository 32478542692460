/*  I guess we need a specialized split button because..?  */
.mat-mdc-raised-button.mat-primary.download-button {
  border-radius: 6px;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  padding-right: 37px;
}
.mat-mdc-raised-button.mat-primary.download-button i {
  position: absolute;
  top: 0px;
  right: 0;
  width: 27px;
  font-style: inherit;
  border-left: 1px solid white;
  font-weight: bold;
  height: 32px;
}
.mat-mdc-raised-button.mat-primary.download-button i.icon-download:before {
  line-height: 35px;
}