
.mat-button-toggle-group,
.mat-button-toggle-standalone,
.mat-mdc-raised-button:not([class*=mat-elevation-z]),
.mat-expansion-panel:not([class*=mat-elevation-z]),
.mat-mdc-fab:not([class*=mat-elevation-z]) {
  box-shadow: none!important;
}

/*  Since we had to remove shadows... Some things now need to be transparent  */
.mat-expansion-panel,
.mat-drawer-container {
  background: transparent;
  background-color: transparent;
}
