/*  Own theming variables  */

// Primary color
$color-primary: #32699c;
$color-primary-transparent: rgba(50, 105, 156, 0.9);

// Primary color (dark)
$color-primary-semidark: #244C73;
$color-primary-dark: #323e4f;

// Secondary color
$color-secondary: #8dc63f;

// Accent colors
$color-off-white: #e6e6e6;
$color-hover-grey: #f5f5f5;
$color-light-grey: #989898;
$color-medium-grey: #58585A;

// Border colors
$color-border-grey: #DCDCDC;

// Material design helpers
$color-mat-label: rgba(0, 0, 0, 0.54);
$color-mat-border: rgba(0, 0, 0, 0.42);

// Other colors
$color-danger: #f44336;
$color-pdf-red: #f71b1a;

/* Progress Bar */
.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #c2d2e1;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
}
.progress .indeterminate {
  background-color: #32699c;
}
.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%; }
  }
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%; }
  }
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}